import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'components/Typo';
import MainTemplate from 'templates/MainTemplate/MainTemplate';

const VideoContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2,
  p {
    text-align: center;
  }

  h2 {
    font-size: 35px;
  }

  p {
    font-size: 20px;
  }

  div {
    max-width: 600px;
    margin-bottom: 50px;
  }
`;

const Planer = () => (
  <MainTemplate>
    <VideoContainer>
      <div>
        <h2>Jak korzystać z planera?</h2>
        <Paragraph>
          Obejrzyj filmik i zobacz, jak możesz zacząć korzystać z planera. Pamiętaj, że jest to tylko propozycja, a planer ma służyć przede wszystkim Tobie. Także rób z nim co chcesz{' '}
          <span role="img" aria-label="sunglassess emoji">
            😎
          </span>
        </Paragraph>
      </div>
      <iframe
        title="Planer video"
        width="50%"
        height="50%"
        src="https://www.youtube.com/embed/vMX5oB5adsU"
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </VideoContainer>
  </MainTemplate>
);

Planer.propTypes = {};

export default Planer;
